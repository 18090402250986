import React from "react";
import FbIcon from "../../Assets/Images/Footer/facebook-icon.svg";
import InstaIcon from "../../Assets/Images/Footer/instagram-icon.svg";
import twitterIcon from "../../Assets/Images/Footer/twitter.png";
import youtube from "../../Assets/Images/Footer/youtube.png";
import linkedin from "../../Assets/Images/Footer/linkedin.png";
import "./Footer.scss";

const Footer = () => {
    return (
        <>
            <section> 
                <div className="bg-footer ">

                </div>
                <div className="d-flex justify-content-center social-icons">
                    <a href="https://www.facebook.com/amciedtech" target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }}>
                        <img src={FbIcon} className="icons" alt="Facebook" />
                    </a>
                    <a href="https://www.instagram.com/a_m_c_i/" target="_blank" rel="noopener noreferrer">
                        <img src={InstaIcon} className="icons" alt="Instagram" />
                    </a>
                    <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQG9vbs2f8YYsQAAAYukI7NIRY-Hp3YcXvcpJw54hg6tkgFPVNfH7nNNxqa3eZTxrA6-F1GLz7JdLDPLOyVTeUWSHElQjsgL-2_8wvCJww91o-i79MM_Qpg_-aDzMEgASxqaPUs=&original_referer=&sessionRedirect=https%3A%2F%2Fin.linkedin.com%2Fcompany%2Famci-edtech" target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} className="linked-inicon" alt="LinkedIn" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCt-3wL-_f-SMdIiXPDAVFGg" target="_blank" rel="noopener noreferrer">
                        <img src={youtube} className="you-tube-icons" alt="YouTube" />
                    </a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Famciedtech" target="_blank" rel="noopener noreferrer">
                        <img src={twitterIcon} className="icons" alt="Twitter" />
                    </a>
                </div>

                <h1 className="d-flex justify-content-center footer-text">All rights reserved 2023</h1>
            </section>

        </>
    );
};
export default Footer;
