import React, { useState } from "react";
import "./WhyZlipp.scss";
import bagVideo from "../../Assets/Video/High Quality Medical Education.mp4";
import collage from "../../Assets/Images/WhyZlipp/collage.png";
import educator from "../../Assets/Images/WhyZlipp/educator.png";
import student from "../../Assets/Images/WhyZlipp/student.png";
import Announcement from "../../Assets/Images/WhyZlipp/Announcement.png";
import Academics from "../../Assets/Images/WhyZlipp/Academics.png";
import Task from "../../Assets/Images/WhyZlipp/task.png";
import Report from "../../Assets/Images/WhyZlipp/Report.png";
import LiveClass from "../../Assets/Images/WhyZlipp/live-class.png";
import classroom from "../../Assets/Images/WhyZlipp/classroom.png";
import attendance from "../../Assets/Images/WhyZlipp/attendance.png";
import assignments from "../../Assets/Images/WhyZlipp/assignments.png";
import Video from "../../Assets/Images/WhyZlipp/video-icon.png";
import Forum from "../../Assets/Images/WhyZlipp/forum-icon.png";
import Assessment from "../../Assets/Images/WhyZlipp/assessment.png";
import Logbook from "../../Assets/Images/WhyZlipp/log-book.png";
import RightArrow from "../../Assets/Images/WhyZlipp/right-arrow.svg";
import lineIcon from "../../Assets/Images/WhyZlipp/line-icon.png";
import movie from "../../Assets/Images/WhyZlipp/movie-videos-icon.svg";

const collegeData = [
  {
    title: "Announcement",
    image: Announcement,
    description:
      "Smart notifications for timely and effective announcements, keeping everyone informed",
  },
  {
    title: "Academics",
    image: Academics,
    description:
      "Capability to upload and store academic documents on the Zlipp cloud for future reference and accessibility.",
  },
  {
    title: "Task",
    image: Task,
    description:
      "Ability to generate tasks for educators, assign these tasks, and monitor their progress effectively",
  },
  {
    title: "Report",
    image: Report,
    description:
      "Detailed reports for college administrators to track and analyze activities and progress of students and educators",
  },
  {
    title: "Live Class",
    image: LiveClass,
    description:
      "Seamless teaching for educators and enriched learning experience for students with self-recording, automated attendance and smart whiteboard with annotations features",
  },
];
const educatorData = [
  {
    title: "Competency Based Medical Learning",
    image: Announcement,
    description:
      "Access preloaded, immersive 3D animated medical and pharmacy content for enhanced learning experiences",
  },
  {
    title: "Attendance",
    image: attendance,
    description:
      "Interactive forum fostering collaborative engagement between students and educators",
  },
  {
    title: "Classroom",
    image: classroom,
    description:
      "Seamlessly manage classroom attendance through integrated biometric and face recognition technology for enhanced efficiency and accuracy.",
  },
  {
    title: "Assignments",
    image: assignments,
    description:
      "Subjective and objective based assignments that are streamlined for convenient submission and evaluation",
  },
];
const studentData = [
  {
    title: "Video",
    image: Video,
    description:
      "The Zlipp LMS offers a repository of pre-uploaded content, including immersive 3D clinical videos for enhanced learning experiences",
  },
  {
    title: "Forum",
    image: Forum,
    description:
      "Interactive forum fostering collaborative engagement between students and educators",
  },
  {
    title: "Record Class",
    image: movie,
    description:
      "Students are equipped with the capability to capture lectures in conjunction with presentations for comprehensive study resources",
  },
  {
    title: "Assessment",
    image: Assessment,
    description:
      "Objective assessments aiding self-evaluation and skill enhancement",
  },
  {
    title: "Logbook",
    image: Logbook,
    description:
      "Comprehensive student logbook tracking academic activities for progress monitoring",
  },
];
const FeatureCard = ({ title, image, description }) => (
  <div className="globle-card  justify-content-center text-center p-4 mt-4">
    <div className="d-flex justify-content-center">
      <div>
        <img
          src={image}
          className="card-globle-icon"
          alt={`Card Image for ${title}`}
        />
      </div>
    </div>
    <div className="card-body">
      <h5 className="card-title mt-4">{title}</h5>
      <p className="globle-card-text mt-4">{description}</p>
    </div>
  </div>
);

const FeatureList = ({ title, features }) => (
  <div className="container">
    <div className="row">
      <div className="col-md-4 text-center p-4">
        <div className="line-icon">
          <img src={lineIcon} />
        </div>
        <div className="text-justify card-main-content">
          <h1>{title}</h1>
          <h2>Features</h2>
          <h3>~What We Do Offer~</h3>
        </div>
      </div>
      {features.map((feature, index) => (
        <div className="col-md-4" key={index}>
          <FeatureCard {...feature} />
        </div>
      ))}
    </div>
  </div>
);
const WhyZlipp = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [activeCard, setActiveCard] = useState(null);

  const handleCardClick = (card) => {
    setActiveCard(card);
    setSelectedCard(card);
  };

  const renderCardContent = () => {
    switch (selectedCard) {
      case "college":
        return (
          <div className="why-zlipp-content">
            <FeatureList title="college" features={collegeData} />
          </div>
        );
      case "educator":
        return (
          <div className="why-zlipp-content">
            <FeatureList title="educator" features={educatorData} />
          </div>
        );
      case "student":
        return (
          <div className="why-zlipp-content">
            <FeatureList title="student" features={studentData} />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div id="why-zlipp">
      <section className="why-zlipp-section">
        <header className="d-flex justify-content-center">
          <div className="angled-image-container">
            <div>
              <h1 className="why-zlipp-text">Why Zlipp?</h1>
              <h1 className="why-zlipp-sub-text">
                It is a revolutionary platform for medical <br />
                education which leverages visual learning to <br /> effortlessly
                convey complex concepts and <br />
                create engaging, emotionally resonant <br /> learning
                experiences for educators, fostering <br />
                deep understanding and longer retention
                <br /> among students
              </h1>
            </div>
            <video autoPlay muted loop className="video-player">
              <source src={bagVideo} type="video/mp4" />
            </video>
          </div>
        </header>
      </section>
      <section>
        <div className="container card-container justify-content-center">
          <div className="card-titles d-flex justify-content-center">
            <h1 className="card-main-text">What Are You Looking For?</h1>
          </div>
          <div className="row">
            <div className="col-md-4 d-flex align-items-center justify-content-center mt-4">
              <div
                className={`card-section text-center ${
                  activeCard === "college" ? "active" : ""
                }`}
                onClick={() => handleCardClick("college")}>
                <div className="d-flex justify-content-center">
                  <div className="card-icon">
                    <img
                      src={collage}
                      className="card-img-top"
                      alt="Card Image 1"
                    />
                  </div>
                </div>
                <div className="card-content">
                  <h5 className="card-title">College</h5>
                  <p className="card-text text-center my-1 p-4">
                    Comprehensive oversight of academics & college management
                  </p>
                  {activeCard !== "college" && (
                    <div className="college-explore-btn">
                      <a href="#" className="explore-hover">
                        Explore More
                      </a>
                      <img src={RightArrow} className="right-arrow" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-center mt-4">
              <div
                className={`card-section text-center ${
                  activeCard === "educator" ? "active" : ""
                }`}
                onClick={() => handleCardClick("educator")}>
                <div className="d-flex justify-content-center">
                  <div className="card-icon">
                    <img
                      src={educator}
                      className="card-img-top"
                      alt="Card Image 2"
                    />
                  </div>
                </div>
                <div className="card-content">
                  <h5 className="card-title">Educator</h5>
                  <p className="card-text text-center my-4 p-2">
                    Access to comprehensive digital teaching methods for smart
                    classes and track student performance
                  </p>
                  {activeCard !== "educator" && (
                    <div className="educator-explore-btn">
                      <a href="#" className="explore-hover">
                        Explore More
                      </a>
                      <img src={RightArrow} className="right-arrow" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-center mt-4">
              <div
                className={`card-section text-center ${
                  activeCard === "student" ? "active" : ""
                }`}
                onClick={() => handleCardClick("student")}>
                <div className="d-flex justify-content-center">
                  <div className="card-icon">
                    <img
                      src={student}
                      className="card-img-top"
                      alt="Card Image 3"
                    />
                  </div>
                </div>
                <div className="card-content">
                  <h5 className="card-title">Student</h5>
                  <p className="card-text text-center my-4 p-2">
                    Access to exclusive 3D Audio Visual Content + Interactive
                    Models and Abundant Learning Content & Self Evaluations of
                    their Performance
                  </p>
                  {activeCard !== "student" && (
                    <div className="student-explore-btn">
                      <a href="#" className="explore-hover">
                        Explore More
                      </a>
                      <img src={RightArrow} className="right-arrow" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {renderCardContent()}
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </section>
    </div>
  );
};

export default WhyZlipp;
