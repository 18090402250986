import React from "react";
import "./PrivacyPolicy.scss";
const PrivacyPolicy = () => (
 <>
  <div class="container-fluid  mb-5">
    <br/>
    <br/>
    <br/>
    <br/>
    <h3 class="text-center main-text"><b>Privacy Policy</b></h3>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h5 class="text-dark">
            Please read the following Privacy policy of the services made
            available on
            <a href="https://lms.medwhiz.in" target="_blank"
              class="text-decoration-none text-primary">https://lms.zlipp.in</a>
            or the equivalent Zlipp LMS Mobile Application available on Google
            Play or Apple Store (“Zlipp LMS”/ “Website”). The Zlipp LMS App or
            Website on which the Services are availed may together be referred
            to for convenience as the “Platform”.<br />
          </h5>
          <h5 class="text-dark">
            For the purpose of these privacy policies, wherever the context so
            requires “Your”, “You" or "User" shall mean any natural or legal
            person who browses the Platform or has agreed to become a
            subscriber to the Services on the Platform by providing login
            credentials while registering on our website as a Subscribed User
            using the computer systems. The term "We", "Us", "Our" shall mean
            Zlipp Educare Private Limited (“Zlipp”).<br />
          </h5>
          <h5 class="text-dark">
            Please ensure that this Privacy Policy is perused by You before
            availing any services from Us. This Privacy Policy shall be
            updated from time to time and to stay abreast with our methods of
            using Your information and protecting Your privacy, please keep
            reviewing this Policy.<br />
          </h5>
          <h5 class="text-dark">
            By viewing the Zlipp Platform, you agree to be bound by the terms
            of this Privacy Policy<br />
          </h5>
          <h5 class="text-dark">
            By using the Zlipp Platform and/or by providing Your information,
            You consent to the collection and use of the information You
            disclose on our Website in accordance with this Privacy Policy,
            including but not limited to your consent for sharing your
            information as per this Privacy Policy. If we decide to change our
            Privacy Policy, We will post those changes on this page so that
            You are always aware of what information We collect, how We use
            it, and under what circumstances We disclose it. If You do not
            agree to the foregoing, please do not continue to use or access
            our Website.<br />
          </h5>
          <h4 class="privacy-text">
            <b>1. Collection of Personally Identifiable Information.</b>
          </h4>
          <h5 class="text-dark">
            <b>1.1 We collect certain information about You to help us serve
              You better. The information collected by Us is of the following
              nature: <br />➢ Name;<br />➢ Telephone Number;<br />➢ Email
              Address;<br />➢ Service Address;<br />➢ Other information about
              the service address which You give Us; <br />➢ Your IP
              address;<br />➢ Information about your device;<br />➢ Network
              information,<br />➢ College/ Institution Details and location<br />➢
              User-uploaded photo and ids<br />➢ Demographic information such
              as postcode, preferences, and interests;<br />
              ➢ Any other personal information which you give us in connection
              while booking a service or is relevant to customer surveys
              and/or offers.</b>
          </h5>
          <h5 class="text-dark">
            <b>1.2 Information provided during Registration to create a
              profile and use this Website and services, you may be asked to
              provide the following information: your name, your username,
              password, email address, the specialty in which you practice or
              intend to practice (selected from a drop-down menu if
              applicable), year of graduation or year of joining the
              undergraduate medical program and the name of your college or
              university. You may also choose to provide a picture to be
              associated with your profile.</b>
          </h5>
          <h5 class="text-dark">
            <b>1.3 We will store your username, country, and specialty on an
              unencrypted server. Your password is cryptographically hashed
              and your email address is encrypted. These information elements
              are referred to collectively as your “Personal User
              Information.” We collect and hold this information for the
              purpose of administering your use of the Application.</b>
          </h5>
          <h5 class="text-dark">
            <b>1.4 You are solely responsible for<br />
              (1) maintaining the strict confidentiality of your Personal User
              Information,<br />
              (2) not allowing another person to use your Personal User
              Information to access the Services,<br />
              (3) any and all damages or losses that may be incurred or
              suffered as a result of any activities that occur in your
              Account,<br />
              (4) ensuring that the information submitted by you complies with
              our terms and conditions,<br />
              (5) ensuring that the information provided by you is correct and
              updated from time to time.<br />
            </b>
          </h5>
          <h5 class="text-dark">
            <b>1.6 You agree to immediately notify Zlipp in writing by email
              to support@zlipp.in of any unauthorized use of your Personal
              User Information or any other breach of security. Zlipp is not
              and shall not be liable for any harm arising from or relating to
              the theft of your Personal User Information that is under Your
              control, your disclosure of your Personal User Information, or
              the use of your Personal User Information by another person or
              entity.
            </b>
          </h5>
          <h5 class="text-dark">
            <b>1.7 On receiving personal information about You, You no longer
              remain anonymous to Us. We may use this information to do
              internal research on demographics, interests, and behavior to
              better understand, protect and serve our customers. This
              information is compiled and analyzed on an aggregated basis. We
              indicate fields that are mandatorily required to be filled and
              fields that are optional. You may decide whether or not to
              provide such information to Us.</b>
          </h5>
          <h5 class="text-dark">
            <b>1.8 You may choose not to provide us with any personal
              information or information as required to provide any Services.
              If we do not receive the information required, we may choose not
              to provide you with such a Service. Service shall have the
              meaning attributed to the phrase in the Terms of Use.</b>
          </h5>
          <h5 class="text-dark">
            <b>1.9 On our Website, you can browse without telling Us who you
              are or revealing any personal information about Yourself. We may
              automatically track certain information about You based on Your
              behavior on our Website. This information may include the URL
              that You just came from (whether this URL is on our Website or
              not), which URL You next go to (whether this URL is on our
              Website or not), Your browser information, and Your IP
              address.</b>
          </h5>
          <h5 class="text-dark">
            <b>
              1.10 On our Websites, We use data collection devices such as
              "cookies" on certain pages to help analyze our web page flow,
              measure promotional effectiveness, and promote trust and safety.
              "Cookies" are small files placed on your hard drive that assist
              Us in providing our services. We offer certain features that are
              only available through the use of a "cookie". You are always
              free to decline our cookies if Your browser permits, although in
              that case, You may not be able to use certain features on the
              Websites. Additionally, You may encounter "cookies" or other
              similar devices on certain pages of the Website that are placed
              by third parties. We do not control the use of cookies by third
              parties.</b>
          </h5>
          <h4 class="privacy-text"><b>2. Use of Personal Information</b></h4>
          <h5 class="text-dark">
            <b>2.1 The information collected by Us through our Website is used
              by Us for various purposes to enable us to serve you better:
              <br />(a) To find third party service providers;<br />
              (b) Internal record keeping;<br />
              (c) We may use the information to improve our products and
              services;<br />
              (d) We may periodically send promotional emails or messages on
              the Website about new products, special offers, or other
              information which We think You may find interesting using the
              email address which You have provided;<br />
              (e) From time to time, we may use the information to customize
              the Website according to your interests.<br />
            </b>
          </h5>
          <h5>
            <b>
              2.2 We may use personal information to resolve disputes that may
              arise with the use of our Services, help promote a safe service
              to all the customers, measure consumer interest in our services,
              customize your experience, detect and protect Us against error,
              fraud, and other criminal activity, enforce our terms and
              conditions.</b>
          </h5>
          <h5 class="text-dark">
            <b>2.3 We identify and use your IP address to help diagnose
              problems with our server and to administer our Websites. Your IP
              address is also used to help identify You and to gather broad
              demographic information.</b>
          </h5>
          <h4 class="privacy-text">
            <b>3. Sharing of Personal Information</b>
          </h4>
          <h5 class="text-dark">
            <b>3.1 We may share your information with payment service
              providers, regulatory authorities, and third-party agencies in
              the event of any request from such authorities.</b>
          </h5>
          <h5 class="text-dark">
            <b>3.2 We may disclose Your personal information if required to do
              so by law or in the good faith and belief that such disclosure
              is reasonably necessary to respond to subpoenas, court orders,
              or other legal processes. We may disclose personal information
              to law enforcement offices, third party rights owners, or others
              in the good faith belief that such disclosure is reasonably
              necessary to enforce our Terms or Privacy Policy; respond to
              claims that an advertisement, posting or other content violates
              the rights of a third party; or protect the rights, property or
              personal safety of our customers or the general public.</b>
          </h5>
          <h5 class="text-dark">
            <b>3.3 We and our affiliates will share/sell some or all of the
              collected information with another business entity should We (or
              our assets) plan to merge with, or be acquired by that business
              entity, or reorganization, amalgamation, restructuring of
              business. Should such a transaction occur, that other business
              entities (or the new combined entity) will be required to follow
              this Privacy Policy concerning all the information collected?</b>
          </h5>
          <h5 class="text-dark">
            <b>3.4 We do not disclose personal information about identifiable
              individuals to advertisers, but We may provide them with
              aggregate and/or anonymized information about You to help
              advertisers reach the kind of audience they want to target. We
              may make use of the information We have collected from You to
              enable Us to comply with our advertisers' wishes by displaying
              their advertisement to that target audience.</b>
          </h5>
          <h5 class="text-dark">
            <b>3.5 If you choose to subscribe to any Marrow plan you may be
              required to upload a copy of a valid government-issued
              identification document to allow access to the Platform.</b>
          </h5>
          <h4 class="privacy-text"><b>4. Information Safety</b></h4>
          <h5 class="text-dark">
            <b>4.1 All information is saved and stored on servers that are
              secured with passwords and pins to ensure no unauthorized person
              has access to it. Once your information is in our possession we
              adhere to strict security guidelines, protecting it against
              unauthorized access.</b>
          </h5>
          <h4 class="privacy-text"><b>5. Choice/Opt-Out</b></h4>
          <h5 class="text-dark">
            <b>5.1 We provide all customers with the opportunity to opt-out of
              receiving non-essential (promotional, marketing-related)
              communications from Us on behalf of our partners, and from Us in
              general, after providing Us with personal information. If You
              want to remove your contact information from all lists and
              newsletters, please write to [team@zlipp.in].</b>
          </h5>
          <h4 class="privacy-text"><b>6. Third-Party Links</b></h4>
          <h5 class="text-dark">
            <b>6.1 We use third-party advertising companies to serve
              advertisements when You visit our Website. These companies may
              use information (not including your name, address, email
              address, or telephone number) about your visits to this and
              other Websites in order to provide advertisements about services
              of interest to You.
            </b>
          </h5>
          <h5 class="text-dark">
            <b>All other terms and conditions as applicable under the Terms
              and Conditions of Use <a>https://lms.zlipp.in</a> will be
              applicable to You and will be read along with this Privacy
              Policy.</b>
          </h5>
        </div>
      </div>
    </div>
  </div>
 </>
);

export default PrivacyPolicy;
