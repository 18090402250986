import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import logoIcon from "../../Assets/Images/Home/Zlipp-icon.png";
import "./Navbar.scss";

const Navbar = () => {
  const [mobile, setMobile] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768);
      setMobileMenuOpen(false);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };
  return (
    <>
      <nav className="navbar">
        <h3 className="logo">
          <img src={logoIcon} className="img-fluid" alt="Logo" />
        </h3>
        <div
          className="menu-icon"
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
          ☰
        </div>
        <ul
          className={
            mobile && isMobileMenuOpen ? "nav-links-mobile" : "nav-links"
          }>
          <NavLink
            to="/"
            className="home"
            activeClassName="active"
            onClick={closeMobileMenu}>
            <li>Home</li>
          </NavLink>
          <NavLink
            to="/about"
            className="about"
            activeClassName="active"
            onClick={closeMobileMenu}>
            <li>About</li>
          </NavLink>
          <NavLink
            to="/whyzlipp"
            className="whyzlipp"
            activeClassName="active"
            onClick={closeMobileMenu}>
            <li>Why Zlipp</li>
          </NavLink>
          {/* <NavLink
            to="/help"
            className="help"
            activeClassName="active"
            onClick={closeMobileMenu}>
            <li>Help</li>
          </NavLink> */}
          {/*
          <Link to='/login' className='login' onClick={closeMobileMenu}>
            <li className='btn'>Login</li>
          </Link>
          */}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
