import React, { useEffect, useState } from "react";
import "./About.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import about from "../../Assets/Images/About/about.png";
import client1 from "../../Assets/Images/About/client1.png";
import client2 from "../../Assets/Images/About/client2.png";
import client3 from "../../Assets/Images/About/client3.png";
import client4 from "../../Assets/Images/About/client4.png";
import client5 from "../../Assets/Images/About/client5.png";
import client6 from "../../Assets/Images/About/client6.png";
import Rajivgandi from "../../Assets/Images/About/Rajivgandi.png";
import ananta from "../../Assets/Images/About/ananta.png";
import nims from "../../Assets/Images/About/nims.png";
import starimg from "../../Assets/Images/About/starimg.svg";
import background from "../../Assets/Images/About/background.png";
import Slider from "react-slick";

const cardData = [
  {
    image: client1,
    content: {
      title: "BGS Global Institute Of Medical Science,",
      location: "Bangalore",
    },
  },
  {
    image: client2,
    content: {
      title: "L.N. Medical College & J.K. Hospital Bhopal",
      location: "Bhopal",
    },
  },
  {
    image: client3,
    content: {
      title: "Adhichunchanagiri Institute Of Medical Science ",
      location: "B.G. Nagara",
    },
  },
  {
    image: client4,
    content: {
      title: "Sapthagiri Institute of Medical Science & Research Center, ",
      location: "Bangalore",
    },
  },
  {
    image: client5,
    content: {
      title: "ESIC Medical College And Hospital Rajajinagar,",
      location: " Bangalore",
    },
  },
  {
    image: client6,
    content: {
      title: "Shri Jayadeva Institute of Cardiovascular Science And Research, ",
      location: "Bangalore",
    },
  },
  {
    image: Rajivgandi,
    content: {
      title: "Rajiv Gandhi Institute Of Health Science, ",
      location: "Bangalore",
    },
  },
  {
    image: ananta,
    content: {
      title: "Ananta Institute Of Medical Science And Research Center, ",
      location: "Udaipur",
    },
  },
  {
    image: nims,
    content: {
      title: "NIMS University Rajasthan, ",
      location: "Jaipur",
    },
  },
];

const About = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleCardSlide = () => {
    setActiveIndex(
      (prevActiveIndex) => (prevActiveIndex + 1) % cardData.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(handleCardSlide, 3000);

    return () => clearInterval(intervalId);
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="about">
      <section className="main-screen-about">
        <div>
          <img src={about} className="about-img" />
        </div>
        <div className="about-text">About US</div>
        <div className="text-zlip">
          Zlipp is an innovative learning management system designed to
          revolutionize medical education. With Zlipp, students can plan their
          medical studies in a captivating manner, gaining access to live
          classes, an immense library of study materials, breathtaking
          stereoscopic 3D animated videos, and other engaging clinical content.
        </div>
        <div className="second-text">
          By downloading the Zlipp app, users can enjoy a wide variety of
          learning content, ensuring a comprehensive and immersive educational
          experience.Zlipp offers competency-based learning, introducing a
          scoring system that defines digital competency and enables students to
          track their progress effectively. It also supports module-based
          learning, allowing users to define their course-wise learning modules
          and obtain certification upon completion. The platform’s audio-video
          content support transforms ideas into visually stunning effects,
          enhancing the learning experience.
        </div>
      </section>
      <div className="background-curosal">
        <div>
          <img src={background} className="background-img-carousel" />
        </div>
        <section className="inline-carousel">
          <div>
            <h2 className="our-clients-text">
              <b>Our Clients</b>
            </h2>
          </div>
          <div className="polygon">
            <img src={starimg} className="polygon-img" />
          </div>
          <br />

          <Slider {...settings}>
            {cardData.map((card, index) => (
              <div key={index} className="card">
                <div className="card-body">
                  <div className="card-image">
                    <img
                      src={card.image}
                      className="client-image"
                      alt={`Client ${index + 1}`}
                    />
                  </div>
                  <div className="card-content">
                    <b>{card.content.title}</b>
                    <b>{card.content.location}</b>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};

export default About;
